<template>
  <label
    class="checkbox"
    :class="[{
      'checkbox--semi-selected': semiSelected && value,
      'checkbox--disabled': disabled,
      'checkbox--checked': value,
      'checkbox--radio': type === 'radio',
    }, `checkbox--surface-${surface}`]"
  >
    <input
      :id="id"
      :name="name"
      :value="value"
      :checked="value"
      :disabled="disabled"
      type="checkbox"
      @change="updateValue($event.target.checked)"
    >
    <span class="checkbox__box" />

    <div v-if="description" class="checkbox__label--wrapper">
      <span class="checkbox__label">
        <slot>{{ label }}</slot>
      </span>

      <span class="checkbox__description">
        <slot>{{ description }}</slot>
      </span>
    </div>

    <span v-else-if="label || $slots.default" class="checkbox__label">
      <slot>{{ label }}</slot>
    </span>
  </label>
</template>

<script>
import SurfaceHelper from 'mixins/surface_helper';

export default {
  mixins: [SurfaceHelper],
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      default: 'checkbox',
      validator: value => ['checkbox', 'radio'].includes(value),
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // semi selected works correctly only if value == true
    semiSelected: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  @apply flex flex-row cursor-pointer items-center;

  input[type="checkbox"] {
    @apply absolute cursor-pointer h-0 w-0;
  }

  &:hover .checkbox__box {
    background-image: url('../../../../assets/images/checkboxes/checkbox-hover.svg');
  }

  &--center-vertical {
    @apply items-center;

    .checkbox__label {
      @apply mt-0;
    }
  }

  &--search-bar {
    @apply pt-3 w-fit;
  }

  &--surface-accent #{&}__label {
    @apply text-neutral-200;
  }

  &--surface-accent#{&}--checked #{&}__box {
    background-image: url('../../../../assets/images/checkboxes/checkbox-checked.svg?fill=#0121A8 .tick');
  }

  &__label--wrapper {
    @apply flex items-start flex-col gap-[1px];
  }

  &__label {
    @apply ml-2 inline-block grow text-neutral-900 leading-[18px] overflow-hidden;
  }

  &__description {
    @apply ml-2 inline-block grow mt-1 text-neutral-600 overflow-hidden text-sm font-normal;
  }

  &__box {
    @apply content-[''] inline-block h-6 w-6 shrink-0;

    background: url('../../../../assets/images/checkboxes/checkbox.svg') transparent;
    background-size: 24px 24px;
  }

  &.checkbox--radio {
    .checkbox__box {
      background-image: url('../../../../assets/images/radioboxes/radiobox.svg');
    }

    &:hover .checkbox__box {
      background-image: url('../../../../assets/images/radioboxes/radiobox-hover.svg');
    }

    &.checkbox--checked {
      .checkbox__box {
        background-image: url('../../../../assets/images/radioboxes/radiobox-checked.svg');
      }
    }

    &.checkbox--transparent.checkbox--checked {
      .checkbox__box {
        background-image: url('../../../../assets/images/radioboxes/radiobox-checked-transparent.svg');
      }
    }

    &.checkbox--disabled {
      .checkbox__box {
        background-image: url('../../../../assets/images/radioboxes/radiobox-disabled.svg');
      }
    }

    &.checkbox--checked.checkbox--disabled {
      .checkbox__box {
        background-image: url('../../../../assets/images/radioboxes/radiobox-checked-disabled.svg');
      }
    }
  }

  &.checkbox--checked {
    .checkbox__label {
      @apply font-medium;
    }

    .checkbox__box {
      background-image: url('../../../../assets/images/checkboxes/checkbox-checked.svg');
    }
  }

  &.checkbox--disabled {
    .checkbox__label {
      @apply text-neutral-400;
    }

    .checkbox__box {
      background-image: url('../../../../assets/images/checkboxes/checkbox-disabled.svg');
    }
  }

  &.checkbox--transparent.checkbox--checked {
    .checkbox__box {
      background-image: url('../../../../assets/images/checkboxes/checkbox-checked-transparent.svg');
    }
  }

  &.checkbox--semi-selected.checkbox--checked {
    .checkbox__box {
      background-image: url('../../../../assets/images/checkboxes/checkbox-semi.svg');
    }
  }

  &.checkbox--semi-selected.checkbox--checked.checkbox--disabled {
    .checkbox__box {
      background-image: url('../../../../assets/images/checkboxes/checkbox-semi-disabled.svg');
    }
  }

  &.checkbox--checked.checkbox--disabled {
    .checkbox__box {
      background-image: url('../../../../assets/images/checkboxes/checkbox-checked-disabled.svg');
    }
  }
}
</style>
